import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"

import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Moment from "react-moment"
import { RichText } from "prismic-reactjs"
import Eventlist from "../components/eventlist"

import "moment-timezone"
import "moment/locale/de"

const Image = styled(Img)`
  width: 500px;
  height: 500px;
  margin-bottom: 1rem;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`
const Container = styled.div`
  display: flex;
  margin-top: 4em;
  img {
    width: 400px;
    height: 400px;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0;
    flex-direction: column;
    img {
      width: 100%;
      height: auto;
      margin-bottom: 2em;
    }
  }
`
const ContentContainer = styled.div`
  width: 60%;
  padding-left: 2rem;
  h1 {
    margin-top: 0;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;

    padding-left: 0;

    h1 {
      display: none;
    }
  }
`

const MobileHeading = styled.h1`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`
const Mehr = styled.h3`
  a {
    text-decoration: none;
    color: black;
    :hover {
      padding-left: 0.4rem;
    }
  }
`

class Speaker extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const data = this.props.data.prismic.speaker
    //const akwlist = this.props.data.prismic.akw.edges[0].node.data.speakers
    //const parlamentlist = this.props.data.parlament.edges[0].node.data.speakers

    return (
      <Layout location={this.props.location.pathname}>
        <SEO
          title={data.title[0].text}
          keywords={[`${data.title.text}`, "Referentinnen", "Speaker"]}
          image={data.thumbnail.url}
        />
        <Container>
          <MobileHeading>{data.title[0].text}</MobileHeading>
          <img src={data.thumbnail.url} alt={data.thumbnail.alt} />
          <ContentContainer>
            <h1>
              {data.title[0].text} {data.angefragt && "(*angefragt)"}
            </h1>
            {data.quote && <blockquote>{data.quote[0].text}</blockquote>}
            {!data.description && data.excerpt && RichText.render(data.excerpt)}
            {data.description && RichText.render(data.description)}
            <div>
              {data.link && (
                <Mehr>
                  <a href={data.link.url} target="_blank">
                    Mehr über {data.title.text} erfahren ▸
                  </a>
                </Mehr>
              )}
            </div>
          </ContentContainer>
        </Container>
        <Eventlist
          event={data.events}
          moderator={data.moderator}
          additional={data.additional_events}
        />
      </Layout>
    )
  }
}
export default Speaker

export const pageQuery = graphql`
  query SpeakerBySlug($uid: String!, $lang: String!) {
    prismic {
      speaker(lang: $lang, uid: $uid) {
        is_2020
        title
        link {
          ... on PRISMIC__ExternalLink {
            _linkType
            url
          }
        }
        last_name
        moderator
        thumbnail
        quote
        excerpt
        angefragt
        description
        _linkType
        additional_events {
          additional_event {
            ... on PRISMIC_Event {
              title
              start_time
              _meta {
                uid
                id
              }
              end_time
              format
              formatdescription
            }
          }
        }
        events {
          ... on PRISMIC_Event {
            title
            start_time
            _meta {
              uid
              id
            }
            end_time
            thumbnail
            format
            formatdescription
          }
        }
      }
    }
  }
`
