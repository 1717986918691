import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Moment from "react-moment"
import { RichText } from "prismic-reactjs"

const EventLink = styled(Link)`
  text-decoration: none;
  color: black;
  h1 {
    margin-top: 0;
  }
  h3 {
    margin-bottom: 4rem;
  }
  :hover h1 {
    color: red;
  }
`

const DateFormat = styled.h2`
  display: flex;
  justify-content: space-between;
  border-bottom: none;
`

function Eventlist(props) {
  const { event, additional, moderator } = props
  let allEvents
  if (event === null) {
    allEvents = [...additional.map(a => a.additional_event)]
  } else if (additional[0].additional_event === null) {
    allEvents = [event]
  } else {
    allEvents = [...additional.map(a => a.additional_event, event)]
  }
  return (
    <>
      {allEvents[0] && !moderator && <h1>Events:</h1>}
      {moderator && <h1>Moderiert:</h1>}
      {allEvents[0] &&
        allEvents.map(event => (
          <div key={event._meta.uid}>
            <EventLink to={`/events/${event._meta.uid}`}>
              {RichText.render(event.title)}
              <DateFormat>
                {event.start_time.slice(0, 10) ==
                event.end_time.slice(0, 10) ? (
                  <Moment format="DD.MM.YY" locale="de">
                    {event.start_time}
                  </Moment>
                ) : (
                  <>
                    {" "}
                    <div>
                      <Moment format="DD.MM.YY" locale="de">
                        {event.start_time}
                      </Moment>{" "}
                      {"- "}
                      <Moment format="DD.MM.YY" locale="de">
                        {event.end_time}
                      </Moment>
                    </div>
                  </>
                )}{" "}
                <span>
                  {event.formatdescription && event.formatdescription}
                  {!event.formatdescription && event.format}
                </span>
              </DateFormat>
              <h3>
                Beginn:{" "}
                <Moment format="dddd, HH:mm" locale="de">
                  {event.start_time}
                </Moment>{" "}
                Ende:{" "}
                <Moment format="dddd, HH:mm" locale="de">
                  {event.end_time}
                </Moment>
              </h3>
            </EventLink>
          </div>
        ))}
    </>
  )
}
export default Eventlist
